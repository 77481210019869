<template>
  <div class="contain">
    <div class="left-part">
      <h3>
        {{ titleStatus == 1 || titleStatus == 2 ? "基础配置" : "安检信息" }}
      </h3>
      <el-form
        v-if="titleStatus == 1 || titleStatus == 2"
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="安检计划" prop="periodId">
          <el-select
            v-model="form.periodId"
            placeholder="请选择"
            filterable
            size="small"
            style="width: 100%"
            @change="periodChange"
          >
            <el-option
              v-for="item in periodLists"
              :key="item.id"
              :label="item.periodName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检名称" prop="taskName">
          <el-input v-model="form.taskName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="用户分类" prop="userType">
          <el-select
            v-model="form.userType"
            placeholder="请选择"
            filterable
            size="small"
            style="width: 100%"
            @change="getTemplate"
          >
            <el-option
              v-for="item in userTypes"
              :key="item.id"
              :label="item.name"
              :value="Number(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采集模板" prop="tplId">
          <el-select
            v-if="colList.length > 0"
            v-model="form.tplId"
            placeholder="请选择"
            style="width: 100%"
            filterable
            size="small"
          >
            <el-option
              v-for="item in colList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
          <template v-else>选择用户分类后自动展示</template>
        </el-form-item>
        <el-form-item label="安检模板" prop="scuTplId">
          <el-select
            v-if="checkList.length > 0"
            v-model="form.scuTplId"
            placeholder="请选择"
            style="width: 100%"
            filterable
            size="small"
          >
            <el-option
              v-for="item in checkList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
          <template v-else>选择用户分类后自动展示</template>
        </el-form-item>
        <el-form-item
          label="任务时间"
          prop="startTime"
          style="margin-top: 24px"
        >
          <el-date-picker
            v-model="times"
            type="daterange"
            range-separator="至"
            placeholder="请选择时间段"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="setDate"
            size="small"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="安检员"
          prop="inspectorIds"
          style="margin-top: 24px"
        >
          <el-select
            v-model="form.inspectorIds"
            placeholder="请选择"
            multiple
            filterable
            size="small"
            style="width: 100%"
          >
            <el-option
              v-for="item in checkerList"
              :key="item.id"
              :label="item.realname"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-else-if="titleStatus == 3" class="watchLeft">
        <el-descriptions
          class="margin-top"
          :column="1"
          labelClassName="describLable"
        >
          <el-descriptions-item label="安检计划">{{
            form.periodName
          }}</el-descriptions-item>
          <el-descriptions-item label="安检名称">{{
            form.taskName
          }}</el-descriptions-item>
          <el-descriptions-item label="用户分类">{{
            form.userTypeName
          }}</el-descriptions-item>
          <el-descriptions-item label="采集模板">{{
            form.templateName
          }}</el-descriptions-item>
          <el-descriptions-item label="安检模板">{{
            form.scuTemplateName
          }}</el-descriptions-item>
          <el-descriptions-item label="任务时间">{{
            form.startTime + "-" + form.endTime
          }}</el-descriptions-item>
          <el-descriptions-item label="安检员">
            <template v-if="form.inspectorNames">
              <span v-html="changeLine(form.inspectorNames)"></span>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="right-part">
      <h3>安检用户</h3>
      <template v-if="titleStatus == 1 || titleStatus == 2">
        <div class="TableTool">
          <div class="TableToolLeft">
            <el-button
              type="text"
              size="mini"
              @click="addUser"
              style="
                border: 1px solid #1082ff;
                color: #1082ff;
                padding: 0 11px;
                height: 30px;
              "
              >添加</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="delUser(1)"
              style="
                border: 1px solid #f52424;
                color: #f52424;
                padding: 0 11px;
                height: 30px;
              "
              >移除</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="delUser(2)"
              style="
                border: 1px solid #606266;
                color: #606266;
                padding: 0 11px;
                height: 30px;
              "
              >清空</el-button
            >
          </div>
        </div>
        <div class="tableRight">
          <el-table
            :data="tableData"
            height="calc(100vh - 280px)"
            style="margin-top: 10px"
            border
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign: 'center',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="150" align="center">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column prop="addrName" label="片区名称" align="center">
            </el-table-column>
            <el-table-column
              prop="count"
              label="任务户数"
              width="150"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="tableSub">
            <el-button type="primary" @click="subTask" size="small"
              >确定</el-button
            >
          </div>
        </div>
      </template>
      <template v-else-if="titleStatus == 3">
        <rightDetail :houseCount="form.houseCount"></rightDetail>
      </template>
    </div>
    <!-- 添加 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加片区区域"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="addAreaCon">
        <div class="innerCon">
          <div class="tree">
            <div class="clears">
              <el-input
                placeholder="请输入片区名称关键字"
                size="small"
                v-model="filterText"
                clearable
              >
              </el-input>
              <el-button type="primary" size="mini" class="buttons" @click="searchTree">查询</el-button>
              <el-button type="info" size="mini" @click="resetTree"
                >重置</el-button
              >
            </div>
            <el-tree
              ref="dataTree"
              :data="dataTree"
              :props="defaultProps"
              node-key="trueId"
              show-checkbox
              highlight-current
              :filter-node-method="filterNode"
              @node-click="handleNodeClick"
              @check="handleCheckChange"
              :default-checked-keys="checkedKeys"
              :default-expanded-keys="expandedKeys"
            >
            </el-tree>
          </div>
          <div class="addTable">
            <div class="search-part">
              <el-input
                placeholder="请输入区域名称关键字"
                size="small"
                v-model="areaForm.addrsName"
                style="width: 200px"
              >
              </el-input>
              <el-button
                type="primary"
                size="mini"
                @click="getAreaList"
                style="margin-left: 10px;"
                >查询</el-button
              >
              <el-button type="info" size="mini" @click="resetArea"
                >重置</el-button
              >
            </div>
            <el-table
              ref="dialogTableData"
              :data="dialogTableData"
              height="calc(100% - 110px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                background: '#f8f8f9',
                textAlign: 'center',
              }"
              @selection-change="dialogChoose"
              :row-key="getRowKeys"
            >
              <el-table-column
                type="selection"
                width="150"
                reserve-selection
                align="center"
              >
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column prop="addrsName" label="区域名称" align="center">
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                v-if="areaForm.size != -1"
                :current-page="areaForm.current"
                :background="true"
                :page-sizes="[10, 30, 50,100]"
                :page-size="areaForm.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="areaTotal"
                @size-change="handleDiaSizeChange"
                @current-change="handleDiaCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="calcArea">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import rightDetail from "./rightDetail";
import {
  dict_selectDictListByPid,
  AaumArchive_inspectorDropDown,
} from "../../../RequestPort/securityTask/unResident";
import {
  arumTaskFindAddrByArumUsers,
  arumTaskFindUsersByTaskIdAndAddrId,
  arumTaskSelectTaskById,
  arumTaskTaskInsertOrUpdate,
  arumAddrFindTree,
  arumAddrFindTreeNew,
  arumTaskTemplatePulldown,
  arumTaskScuTemplatePulldown,
  periodList,
  normalTaskAddrsPageList,
  normalAddrTaskInsertOrUpdate,
  normalAddrTaskPageList,
} from "../../../RequestPort/selectQuery/residentRequest.js";
import {
  selectRepeat
} from "../../../RequestPort/dict/cycle";
import { forEach } from 'jszip';
export default {
  name: "addArea",
  components: { rightDetail },
  data() {
    return {
      form: {
        periodId: null, // 安检周期id
        taskName: "", //任务名称
        inspectorIds: [], //安检员id数组
        scuTplId: "", //安检模板id
        tplId: "", //采集模板id
        userType: "",
        startTime: "", //任务时间(开始时间)
        endTime: "", //任务时间(结束时间)
        taskCategory: 1, // 任务类别 1常规区域安检 2常规散户安检 3异常安检
        addsIds: [],
      },
      userTypes: [],
      checkerList: [],
      rules: {
        periodId: {
          required: true,
          message: "请选择安检周期",
          trigger: "change",
        },
        taskName: {
          required: true,
          message: "请输入安检名称",
          trigger: "blur",
        },
        userType: {
          required: true,
          message: "请选择用户分类",
          trigger: "change",
        },
        inspectorIds: {
          required: true,
          message: "请选择安检员",
          trigger: "change",
        },
        tplId: {
          required: true,
          message: "请先添加采集模板",
          trigger: "change",
        },
        scuTplId: {
          required: true,
          message: "请先添加安检模板",
          trigger: "change",
        },
        startTime: {
          required: true,
          message: "请选择任务时间",
          trigger: "change",
        },
      },
      times: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      colList: [], // 采集模板下拉
      checkList: [], // 安检模板下拉
      titleStatus: 1,
      periodLists: [], // 安检周期下拉
      tableData: [],
      dialogVisible: false,
      dataTree: [],
      defaultProps: {
        children: "children",
        label: "name",
        disabled: "disable",
      },
      checkedKeys: [],
      expandedKeys: [],
      filterText: "",
      areaForm: {
        addrsName: "", // 区域名称
        periodId: "", // 周期id
        pid: "", // 片区id
        current: 1, // 当前页,默认值为1
        size: -1, // 当前页条数,默认值为10
        taskId: "", // 任务id
      },
      dialogTableData: [],
      areaTotal: 0,
      chosedArea: [],
      chosedTableSub: [], // 选中的片区列表
      threeActiveId:'',
      newThreeList:[],
      taskIdNew:'',
      oldListValue:[],
      saveList:[],
      liststate:true
    };
  },
  mounted() {
    if (this.$route.query.titleStatus) {
      this.titleStatus = this.$route.query.titleStatus;
    }
    this.taskIdNew=this.$route.query.id
    this.getDesc(this.$route.query.id);
    this.getOption();
  },
  watch: {
    // filterText(val) {
    //   this.$refs.dataTree.filter(val);
    // }
  },
  methods: {
    subTask() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.tableData.length > 0) {
           // if (this.tableData[0].pids) {
              //如果详情接口按组返回此步去重可以去掉
              let newids=[]
              
              this.tableData.forEach((item) => {
                item.pids.forEach(citem => {
                  if(newids.indexOf(citem)==-1){
                    newids.push(Number(citem))
                  }
                });
               // this.form.addsIds.push(...item.pids);
              });
              
              if(newids.length>0){
                this.form.addsIds=newids;
              }
              
            // } else {
            //   this.form.addsIds = this.form.addsIds;
            // }
            // if (this.chosedArea.length) {
            //     this.form.addsIds = this.chosedArea.map(item => {
            //         return item.id
            //     })
          } else {
            if (this.tableData.length === 0) {
              this.$message.error("请添加安检用户");
            }
            return; 
          }
          normalAddrTaskInsertOrUpdate(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.data);
              
              this.close();
              console.log(this.form)
            }
          });
        }
      });
    },
    close() {
      delete this.form.id;
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
            this.newThreeList=[]
            this.dialogTableData=[]
            this.oldListValue= []
            this.chosedTableSub =[]
      this.form.addsIds = []
      this.checkList = [];
      this.colList = [];
      this.tableData = [];
      this.checkedKeys = [];
      this.expandedKeys = [];
      this.times = [];
      this.taskIdNew=''
      this.saveList=[]
      this.$emit("close");
    },
    calcArea() {
      // if (!this.chosedArea.length) {
      //   this.$message.info("请选择片区区域");
      //   return;
      // }
      // let list = this.$refs.dataTree.getCheckedNodes(true);
      // // this.tableData = this.chosedArea
      // console.log(list);
      // let obj = {};
      // list.forEach((item) => {
      //   let pids = [];
      //   this.chosedArea.forEach((inner) => {
      //     if (item.trueId === inner.pid) {
      //       pids.push(inner.id);
      //       obj[item.trueId] = {
      //         count: inner.count,
      //         addrName: item.name,
      //         id: item.trueId,
      //         pids,
      //       };
      //     }
      //   });
      // });
      // this.tableData = Object.values(obj);
      // this.dialogVisible = false;
   
    for (let i = 0; i < this.newThreeList.sort().length; i++) {
        if (this.newThreeList[i] == this.newThreeList[i + 1]) {
            this.newThreeList.splice(i, 1)
            i--
        }
    }
    this.newThreeList=this.newThreeList.filter((el) => {
           return (el.children&&el.children.length>0);
    });
    if (this.newThreeList.length<1) {
        this.$message.info("请选择片区区域");
        return;
      }

    let tablelist=[]
    this.newThreeList.forEach((item)=>{
       let pids = [];
       let count=0
       if(item.children&&item.children.length>0){
        item.children.forEach((inner)=>{
           pids.push(inner.id);
           count+=inner.count
        })
        tablelist.push({
              count,
              addrName: item.name,
              id: item.trueId,
              pids,
            })
       }
    })
    
  
     

     
     this.tableData = tablelist;
      
      this.saveList=JSON.parse(JSON.stringify(this.newThreeList))
this.$refs.dialogTableData.clearSelection()
      this.dialogVisible = false;

    },
    dialogChoose(a) {  
     
       if(!this.liststate){
          return
        }
         console.log(this.newThreeList)     
      if(this.areaForm.pid){//有pid区分的时候
       let e=[]
        a.forEach((row)=>{
             if(row.pid==this.areaForm.pid){
                  e.push(row)
              }
        });
        
          let havesId=false
          let newThreeList=JSON.parse(JSON.stringify(this.newThreeList))
        console.log(a,e)
        console.log(newThreeList)
        console.log(this.areaForm,'this.areaForm')
          newThreeList.forEach((item)=>{
                            if(item.trueId==this.areaForm.pid){
                              let ch =item.children.filter((childrenitem) => {   
                                let havef=false                     
                                this.dialogTableData.forEach(list => {
                                  if(list.id==childrenitem.id){
                                    havef=true
                                  }
                                });
                                return !havef;
                              });  
                              e.push(...ch)
                              item.children=e
                              havesId=true
                            }
          })       

          if(!havesId&&e.length>0){//如果树里没被勾选
          
            
              let data=this.$refs.dataTree.getNode(this.areaForm.pid).data
              data.children=e
              newThreeList.unshift(data)
           
            
          }else if(havesId&&e.length<1){             
             this.$refs.dataTree.setChecked(this.areaForm.pid,false); 
              newThreeList = newThreeList.filter((item) => {
                  return (item.trueId!=this.areaForm.pid);
                });           
          }         
          this.newThreeList=newThreeList
          if(e.length<1){
            this.$refs.dialogTableData.clearSelection()
          }else if(e.length>0){
             this.$refs.dataTree.setChecked(this.areaForm.pid,true);  
          }
     }else{//没有pid区分只能从数据里拿pid然后分组，再循环赋值。并且不能去掉编辑里的数据，所以不能直接赋值
         
       console.log(222)
        let e=a
         if(e.length<1){
          this.$refs.dialogTableData.clearSelection()
        }
         let newThreeList=JSON.parse(JSON.stringify(this.newThreeList))
       // if(e.length>this.oldListValue.length){//添加选择
          let pidGroup = this.groupBy(e,'pid')
       
          let lengthkey=0
          for(let key in pidGroup){
            lengthkey=lengthkey+1
              let havesId=false
              let newrow=[]
              if(newThreeList.length>0){
                newThreeList.forEach((item)=>{
                    if(item.trueId==key){
                        let ch =item.children.filter((childrenitem) => {
                            let havef=false
                            this.dialogTableData.forEach(list => {
                              if(list.id==childrenitem.id){
                                havef=true
                              }
                            });
                            return !havef;
                          });    
                     //  console.log(ch[0]. ch)
                           newrow=[...pidGroup[key],...ch]
                           item.children= newrow   
                        //item.children=pidGroup[key]
                       // newrow=pidGroup[key]
                        havesId=true
                     }else{
                      newrow=JSON.parse(JSON.stringify(item.children)) 
                     }
                })
              }
              //if(!havesId){
             //   newrow=pidGroup[key]
            //  }
            if(!havesId&&newrow.length>0){//如果树里没被勾选
              this.$refs.dataTree.setChecked(key,true);
              let data=this.$refs.dataTree.getNode(key).data
              data.children=newrow
              newThreeList.unshift(data)
            }else if(havesId&&newrow.length<1){             
              this.$refs.dataTree.setChecked(key,false); 
                newThreeList = newThreeList.filter((item) => {
                    return (item.trueId!=key);
                  });           
            }         
           
            if(newrow.length<1){
              this.$refs.dataTree.setChecked(key,false); 
            }
          }
         
          // if(lengthkey<newThreeList.length){
          //    newThreeList = newThreeList.filter((item) => {
          //          let haves=false
          //            for(let key in pidGroup){
          //             if(item.trueId==key){
          //               haves=true
          //             }                      
          //            }
          //            if(!haves){
          //             this.$refs.dataTree.setChecked(item.trueId,false); 
          //            }
          //           return haves;
          //    });    
          // }
           this.newThreeList=newThreeList
        // }else{//取消选择：包括单个取消，多个取消
        //   let cancelList=this.oldListValue.filter((item) => {
        //           let havef=true
        //           e.forEach((eitem)=>{
        //             if(item.id==eitem.id){
        //               havef=false
        //             }
        //           })
        //           return havef;
        //       });
              
           
        //   if(cancelList.length>0){
        //     let pidGroup ={}
        //     if(cancelList.length<2){
        //       let k=cancelList[0].pid
        //       pidGroup[k]=cancelList
        //     }else{
        //       pidGroup=this.groupBy(cancelList,'pid')
        //     }
        //      console.log(this.newThreeList)
        //     for(let key in pidGroup){
        //       this.newThreeList.forEach((item)=>{
        //          if(item.trueId==key){
        //               let children=item.children
        //               children=children.filter((item) => {
        //                   let havef=true
        //                   pidGroup[key].forEach(itemc => {
        //                     if(item.id==itemc.id){
        //                       havef=false
        //                     }
        //                   });
        //                   return havef
        //               })
        //               if(children.length>0){
        //                 item.children=children
        //               }else{
        //                 item.children=null
        //                 this.$refs.dataTree.setChecked(key,false);
        //               }
        //          }
                 
        //       })  
        //     }
        //     this.newThreeList=this.newThreeList.filter((item)=>{
        //       return item.children
        //     })
        //   }
         
        // }
       this.oldListValue=e
        
      }
      
      
    },
    groupBy(array,name){
      const groups = {};
      array.forEach(function (o) {
        const group = JSON.stringify(o[name]);
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return groups;
    },
    getRowKeys(row) {
      return row.id;
    },
    addUser() {
      if(!this.form.userType){
        this.$message.error('请先选择用户分类')
        return
      }
      this.filterText = ''
      this.dialogTableData = [];
      this.areaForm.addrsName = ''
      this.areaForm.periodId = this.form.periodId;
      this.areaForm.pid=''
      this.dialogVisible = true;
      let oldnewThreeList=JSON.parse(JSON.stringify(this.saveList))
      arumAddrFindTreeNew({ level: 3,periodId:this.form.periodId ,taskId:this.taskIdNew,userType:this.form.userType}).then((res) => {
        this.newThreeList=oldnewThreeList
        let c=[]
        let o=[]
       // let lea=this.getFinalNode(JSON.parse(JSON.stringify(res.data)),[])
        if(this.newThreeList&&this.newThreeList.length>0){
            this.newThreeList.forEach(item => {  
                c.push(item.trueId)
                o.push(item.pid)
            });
        }
        this.checkedKeys=c
        this.expandedKeys=o      
       //this.dataTree = res.data; 
       let data= this.setDisNode(res.data); 
       this.dataTree =data;
        this.$nextTick(()=>{   
          this.$refs.dataTree.setCheckedKeys([]);  
          this.$refs.dataTree.setCheckedKeys(c);      
        
        })
      //   this.$nextTick(()=>{
      //    if(this.$refs.dataTree){
      //     this.newThreeList.forEach(item => {  
      //            if(lea.indexOf(item.trueId)==-1){
      //           this.$refs.dataTree.setChecked(item.trueId,false); 
      //          }
      //       });
      //    }
      //  })

      //  console.log(this.dataTree)
       
      });
      // this.$nextTick(() => {
      //   this.$refs.dialogTableData.clearSelection();
      // });
    },
   setDisNode (obj) {
    
    let arr = [] 
    obj.map(item => {
      let obj = item
      if(obj.type!=4){
         obj.disable=true
      }      
      if (item.children) {
        obj.children = this.setDisNode(item.children)
        // item.children
      }
      arr.push(obj)
    })
    return arr
  
  },
    delUser(val) {
      if (val == 1) {
        if (this.chosedTableSub.length) {
          this.$confirm("是否移除", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
          }).then(() => {
            let arr = this.chosedTableSub.map((item) => {
              return item.id;
            });
            this.tableData = this.tableData.filter((item) => {
              return !arr.includes(item.id);
            });
            this.newThreeList=this.newThreeList.filter((el) => {
                return !arr.includes(el.trueId);
            });
            this.saveList=this.saveList.filter((el) => {
                return !arr.includes(el.trueId);
            });
          })
          
        } else {
          this.$message.error("请先选择数据");
        }
      } else {
        this.$confirm("是否清空全部数据", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.tableData = [];
            this.newThreeList=[]
            this.saveList=[]
          })
          .catch(() => {});
      }
    },
    handleSelectionChange(e) {
      this.chosedTableSub = e;
    },
    
    getFinalNode(obj,arr) {
     
      obj.forEach(item => {
        if(item.type == 4){ arr.push(item.trueId)}
        if (item.children) {
          this.getFinalNode(item.children,arr)
        }
        
      })
      return arr
      // let arr = [];

      // // 定义一个函数
      // function xxx(node) {
      //   if (node.children && node.children.length) {
      //     for (let i = 0, len = node.children.length; i < len; i++) {
      //       xxx(node.children[i]);
      //     }
      //   } else {
      //     node.type == 4 && arr.push(node.trueId);
      //   }
      //   return arr;
      // }
      // xxx(data);
      // return arr;
    },
    handleCheckChange(data, checked, indeterminate) {
      //选择的时候勾选的数据和已经处理好的数据融合
      let type4Ids=this.$refs.dataTree.getCheckedNodes(true)
      let newcheck=this.newThreeList//选择好的数据
      type4Ids.forEach((item)=>{//新勾选的         
        let haves=false   
        let c={}
        newcheck.forEach((citem)=>{
          if(item.trueId==citem.trueId){
            haves=true
          }
        })  
        if(!haves){
          newcheck.unshift(item)
        } 
      })
      
     
      this.newThreeList=newcheck
        if(data.type==4){//只有第四级才查询
             this.areaForm.pid = data.trueId;
             this.areaForm.taskId=this.taskIdNew
             this.areaForm.userType=this.form.userType
              normalTaskAddrsPageList(this.areaForm).then((res) => {
                this.dialogTableData = res.data.records;   
                        
                this.treeclick(data)
               
              });
        }
    },
    handleNodeClick(data) {
      if(data.type==4){//只有第四级才查询
        this.areaForm.pid = data.trueId;
        this.areaForm.taskId=this.taskIdNew
        this.areaForm.userType=this.form.userType
        this.getAreaList()
        // this.areaForm.current = 1
        // this.areaForm.size = 30
        // normalTaskAddrsPageList(this.areaForm).then((res) => {
        //   this.dialogTableData = res.data.records;
        //   this.areaTotal = res.data.total;
          
        //   this.treeclick(data)
        
        // });
      }
      
   
    },
    treeclick(data){
      
      this.$nextTick(() => {
        this.liststate=false
                let newThreeList=this.newThreeList
                let dialogTableData=this.dialogTableData
                let active=false

                let type4Ids=this.$refs.dataTree.getCheckedNodes(true)
                console.log(type4Ids)
                let haves=false
                 type4Ids.forEach((item)=>{
                     if(item.trueId==data.trueId){
                      haves=true
                     }
                 })

                 let newList=[]
                  let nl=JSON.parse(JSON.stringify(newThreeList))
                  console.log(nl)
                 nl.forEach((item)=>{
                    if(item.trueId==data.trueId){
                      if(item.children&&item.children>0){
                        let ch =item.children.filter((childrenitem) => {
                            let havef=false
                            this.dialogTableData.forEach(list => {
                              if(list.id==childrenitem.id){
                                havef=true
                              }
                            });
                            return !havef;
                          }); 
                      
                        newList=ch
                      }   
                       
                     }
                })



                 let havechild=false
                     
                   
                    dialogTableData.forEach(item => {
                       let c=false
                       if(haves&&nl.length>0){                         
                         nl.forEach((itemc)=>{
                            if(itemc.trueId==data.trueId){
                              if(itemc.children&&itemc.children.length>0){//如果已经勾选过，用原来勾选的值，不新赋值
                                itemc.children.forEach(itemd => {
                                  if(itemd.id==item.id){                                    
                                    c=true
                                  }                              
                                });
                              }else{
                                havechild=true
                              }
                            }
                          })                          
                       }
                       if(havechild){c=true}
                     
                      if(c){
                        newList.push(item)
                      }                    
                      this.$refs.dialogTableData.toggleRowSelection(item,c)
                    })
                    
                     newThreeList.forEach((item)=>{
                        if(item.trueId==data.trueId){

                          item.children=newList
                        }
                     })
        this.liststate=true
       })

    },
    getAreaList() {
      if (!this.areaForm.pid) {
        this.$message.info('请先选择片区')
        return
      }
      this.areaForm.current = 1
      this.areaForm.taskId=this.taskIdNew
      this.areaForm.userType=this.form.userType
     //  this.$refs.dialogTableData.clearSelection()
      normalTaskAddrsPageList(this.areaForm).then((res) => {
        this.dialogTableData = res.data.records;        
        this.areaTotal = res.data.total;
       // this.oldListValue=res.data.records

       this.$nextTick(()=>{
            this.liststate=false
           
            this.dialogTableData.forEach(item => {
                          let c=false     
                          let newThreeList=JSON.parse(JSON.stringify(this.newThreeList))                                       
                            newThreeList.forEach((itemc)=>{                           
                                  if(itemc.children&&itemc.children.length>0){
                                    itemc.children.forEach(itemd => {
                                      if(itemd.id==item.id){      
                                        console.log(item.id)                              
                                        c=true
                                      }                              
                                    });
                                  }                        
                              })   
             
                this.$refs.dialogTableData.toggleRowSelection(item,c)
            })
            this.liststate=true
       })
        
        
      });
    },
    resetArea() {
      this.areaForm.addrsName = ''
      this.getAreaList()
    },
    getOption() {
      dict_selectDictListByPid({ pid: 732 }).then((res) => {
        this.userTypes = res.data;
      });

      AaumArchive_inspectorDropDown({}).then((res) => {
        this.checkerList = res.data;
      });
    },
    getDesc(id) {
      this.$nextTick(() => {
        if (this.titleStatus == 1) {
          this.getPeriod().then((data) => {
            if (this.$refs.ruleForm) {
              this.$refs.ruleForm.resetFields();
            }
            !this.form.periodId ? (this.form.periodId = data[0].id) : "";
          });
        } else {
          arumTaskSelectTaskById({ taskId: id }).then((res) => {
            this.getPeriod();
            arumTaskTemplatePulldown({ userType: res.data.userType }).then(
              (innerRes) => {
                // 采集模板
                this.colList = innerRes.data;
              }
            );
            arumTaskScuTemplatePulldown({ userType: res.data.userType }).then(
              (innerRes) => {
                // 安检模板
                this.checkList = innerRes.data;
              }
            );
            this.form = res.data;
            this.form.periodId = this.form.periodId.toString();
            this.times = [
              res.data.startTime.replace(/\//g, "-"),
              res.data.endTime.replace(/\//g, "-"),
            ];
          });
          normalAddrTaskPageList({ taskId: id }).then((res) => {
            
            this.form.addsIds = res.data.addrsIds;

            //暂时先全部将ids存进去
            let treeSelectData=[]
            let data=res.data.addrList
            if(data&&data.length>0){
              
              data.forEach((item)=>{  //组合自己要的数据格式
                let children=[]
                let addrsIds=item.addrsIds
                if(addrsIds&&addrsIds.length>0){
                  addrsIds.forEach((item)=>{
                    children.push({id:`${item}`,count:0}) 
                  })
                }
                children[0].count=item.count//这是避免编辑算错值
                let newItem={
                  id:item.id,
                  trueId:item.id,
                  name:item.addrName,
                  type:4,
                  children:children
                }
                item.pids=item.addrsIds
                treeSelectData.push(newItem)
              })
              this.newThreeList=treeSelectData
              this.saveList=treeSelectData
              this.tableData = res.data.addrList;
            }
          });
        }
      });
    },
    clearRightData(){
        this.newThreeList=[]
        this.tableData =[]
        this.saveList=[]
    },
    // 获取是否可重复
    async getRepeated() {
      let data = selectRepeat().then((res) => {
        return res.data === 1 ? true : false;
      });
      return Promise.resolve(data);
    },
    async getPeriod() {
      // 安检周期下拉
      let data = periodList().then((res) => {
        this.periodLists = res.data;

        return res.data;
      });
      return Promise.resolve(data);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    periodChange(e) {
      // 安检周期变化重新请求用户树
      //   this.getTemplate(e)
      //   this.getRepeated().then((data) => {
      // if (!data) {
      //   arumTaskFindUserTreeEcho({
      //     userType: this.form.userType,
      //     periodId: this.form.periodId,
      //   }).then((res) => {
      //     this.dataTree = res.data.data;
      //     this.checkedKeys = [];
      //     this.expandedKeys = res.data.defaultExpandedKeys;
      //     this.form.userIds = [];
      //     this.tableData = [];
      //     this.$refs.ruleForm.clearValidate("userIds");
      //   });
      // }
      //   });
       selectRepeat().then(res => {
            if(res.data === 2){
                this.clearRightData()
            }
        })
    
    },
    setDate(val) {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
    },
    getTemplate(val) {
      arumTaskTemplatePulldown({ userType: val }).then((res) => {
        this.form.tplId = "";
        if (res.data) {
          this.colList = res.data;
        } else {
          this.colList = [];
        }
        // this.$refs.ruleForm.validateField("templateName");
        this.clearRightData()
      });
      arumTaskScuTemplatePulldown({ userType: val }).then((res) => {
        this.form.scuTplId = "";
        if (res.data) {
          this.checkList = res.data;
        } else {
          this.checkList = [];
        }
        this.$refs.ruleForm.validateField(["scuTplId", "tplId"]);
      });
      //   arumAddrFindTree({
      //     userType: this.form.userType,
      //     periodId: this.form.periodId,
      //   }).then((res) => {
      //     this.dataTree = res.data.data;
      //     this.expandedKeys = res.data.defaultExpandedKeys;
      //     this.form.userIds = [];
      //     this.tableData = [];
      //     this.$refs.ruleForm.clearValidate("userIds");
      //   });
    },
    handleDiaSizeChange(val) {
      this.areaForm.current = 1;
      this.areaForm.size = val;
      this.handleCheckChange();
    },
    handleDiaCurrentChange(val) {
      this.areaForm.current = val;
      this.handleCheckChange();
    },
    changeLine(e) {
      return e.replace(/,/g,"<br />")
    },
    searchTree() {
      this.$refs.dataTree.filter(this.filterText);
    },
    resetTree() {
      this.filterText = ''
      this.$refs.dataTree.filter(this.filterText);
    }
  },
};
</script>
 
<style scoped lang="less">

// /deep/ .el-tree-node{
//       .is-leaf + .el-checkbox .el-checkbox__inner{
//           display: inline-block;
//       }
//       .el-checkbox .el-checkbox__inner{
//           display: none;
//       }
// }
.contain {
  height: calc(100% - 114px);
  background-color: #e9eef3;
  display: flex;
  h3 {
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    border-bottom: 1px solid #ebebeb;
  }
  .left-part {
    background-color: #fff;
    width: 280px;
    height: 100%;
    margin-right: 10px;
    /deep/.el-form {
      padding: 0 20px;
      .el-form-item {
        margin-bottom: 10px;
      }
      .el-form-item__label {
        height: 32px;
      }
    }
    .watchLeft {
      padding: 36px 20px;
      /deep/.describLable {
        color: #909399;
      }
      /deep/.el-descriptions-item {
        padding-bottom: 31px;
      }
    }
  }
  .right-part {
    background-color: #fff;
    width: calc(100% - 290px);
    height: 100%;
    .TableTool {
      padding: 10px 20px;
    }
    .tableRight {
      padding: 0px 20px;
      .tableSub {
        float: right;
        margin-top: 10px;
      }
    }
  }
}
.addAreaCon {
  // padding: 30px;
  height: 560px;
  .innerCon {
    border: 1px solid #dcdfe6;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    .clears {
      display: flex;
      align-items: center;
      button  {
        margin-left: 10px;
      }
    }
    .tree {
      width: 364px;
      height: 100%;
      overflow: auto;
      border-right: 1px solid #dcdfe6;
      padding: 10px;
      box-sizing: border-box;
    }
    .addTable {
      padding: 10px;
      width: calc(100% - 364px);
      .search-part {
        display: flex;
      }
    }
  }
}
/deep/.el-dialog__header {
  background-color: #F9F9F9;
  padding: 20px;
  font-weight: bold;
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__title:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #1082FF;
    margin-right: 10px;
    vertical-align: middle;
  }
}
</style>